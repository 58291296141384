<template>
  <div class="mb-10 v-banner-section">
    <v-carousel height="600" :show-arrows="isHovering" cycle>
      <v-carousel-item v-for="(item, index) in banners" :key="index">
        <div
          class="banner-bg"
          :style="{ backgroundImage: 'url(' + item.url + ')' }"
        ></div>
      </v-carousel-item>
    </v-carousel>
  </div>
</template>

<script>
export default {
  name: "BannerSection",
  props: {
    banners: {
      type: Array,
      default: () => [],
    },
  },
  data: () => ({
    isHovering: true,
    model: 0,
    windowWidth: null,
    isLargeScreen: window.innerWidth > 768,
  }),
  created() {
    window.addEventListener("resize", this.handleResize);
    console.log(this.banners);
  },
  destroyed() {
    window.removeEventListener("resize", this.handleResize);
  },
  methods: {
    handleResize() {
      this.isLargeScreen = window.innerWidth > 768;
    },
  },
};
</script>

<style>
.v-banner-section .fa-chevron-left:before,
.v-banner-section .fa-chevron-right:before {
  font-size: 20px;
}
.banner-bg {
  background-size: cover;
  background-position: center center;
  height: 600px;
}
</style>
